import logo from '../images/logo.png'
import { Link } from 'react-router-dom'


function Footer({ number }) {

  return (
    <footer className='footer'>
      <div className='footer-section'>
        <div>
          <Link to='/'><img src={logo} alt="gym_logo" className='footer-logo' /></Link>
        </div>
        <div className='info'>
          <p>Contact</p>
          <p> <a style={{
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '22px', color: '#FFFFFF'
          }} href="mailto: thedragonfitnesscenter@gmail.com"> thedragonfitnesscenter@gmail.com</a></p>
          <p>(+977) 9801608080</p>
        </div>
        <div className='info'>
          <p>Important Links</p>
          <Link
          style={{fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '22px', color: '#FFFFFF', textDecoration:'none',display:'block',marginBottom:'35px'}} className='link' to="/about">About Us</Link>
          <Link style={{fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '22px', color: '#FFFFFF', textDecoration:'none'}} className='link' to="/services">Services</Link>
        </div>
        <div className='info'>
          <p>
           
            Social Media</p>
          <p>
          <a style={{color:'#ffdf00',textDecoration:'none'}} href='https://www.facebook.com/thedragonfitnesscenter/?locale=ms_MY' target='_blank' rel="noreferrer">
          Facebook
              </a>
            </p>
            <p>
          <a style={{color:'#ffdf00',textDecoration:'none'}} href='https://www.tiktok.com/@thedragonfitnesscenter' target='_blank' rel="noreferrer">
          TikTok
              </a>
            </p>
        </div>
      </div>
      <div>
        <p style={{textAlign:'center'}}>Copyright © 2023 The Dragon Fitness Center. All Rights Reserved.
        <p style={{marginTop:5}}>
  Designed and developed by <a href="https://sandconsole.com" rel="noreferrer" target={'_blank'} style={{color:'#ffdf00',textDecoration:'none'}}>Sand Console</a>
</p>
        </p>

      </div>
    </footer>
  )
}


export default Footer
