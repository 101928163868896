import React from 'react'
import Banner from '../../components/Banner'
import Services from './Services'
import Footer from '../../components/Footer'

function ServicePage() {
  return (
    <div>
      <Banner heading=" Services" subheading='Start your fitness journey with us'/>
               <Services/>
               <Footer number='01-4128879 / (+977) 9838765670 '/>
    </div>
  )
}

export default ServicePage
