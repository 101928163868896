import React from 'react'
import bestprice from '../../images/best-price.png'
import quality from '../../images/quality.png'
import satisfaction from '../../images/satisfaction.png'
import trainer from '../../images/trainer.png'
import './home.css'

function Feature() {
  return (
    <div className='features'>
      <div>
        <img src={satisfaction} alt=" customer satisfaction" />
        <p>Real Results</p>
      </div>
      <div>
      <img src={quality} alt=" quality" />
        <p>Best Service</p>
      </div>
      <div>
      <img src={bestprice} alt=" best price" />
        <p>Reasonable Price</p>
      </div>
      <div>
      <img src={trainer} alt="skilled trainer" />
        <p>Skilled Trainers</p>
      </div>
    </div>
  )
}

export default Feature
