import React from 'react'
import gym from '../../images/gym.png'
import './home.css'


function TrainBetter() {
  return (
    <div className='train-container'>
      <div className='train-desc'>
        <p>Train Better</p>
        <p>Gym training involves personalized workout routines, expert guidance, and motivation. Trainers assess clients, create customized plans, and monitor form and technique, while also adapting the program as clients progress, ensuring continuous growth and improvement. They educate on nutrition and provide constant support, helping individuals achieve their fitness goals..</p>
      </div>
      <div className='gym-image'>
        <img src={gym} alt=" gym_image" />
      </div>
    </div>
  )
}

export default TrainBetter
