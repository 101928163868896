import React from 'react'
import Banner from '../../components/Banner'
import Feature from './Feature'
import TrainBetter from './TrainBetter'
import TopWorkout from './TopWorkout'
import Membership from './Membership'
import Footer from '../../components/Footer'

function HomePage() {
  return (
    <div>
      <Banner heading="Improve your fitness level
for the better" subheading='Start your fitness journey with us' />
      <Feature />
      <TrainBetter />
      <TopWorkout />
      <Membership />
      <Footer number='9838765670, 9738478903' />
    </div>
  )
}

export default HomePage
