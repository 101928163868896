import React from 'react'
import Banner from '../../components/Banner'
import Contact from './Contact'
import Footer from '../../components/Footer'

function ContactPage() {
  return (
    <div>
      <Banner heading="Contact Us" subheading='Start your fitness journey with us' />
      <Contact />
      <Footer number='01-4128879 / (+977) 9838765670 ' />
    </div>
  )
}

export default ContactPage
