import logo from '../images/logo.png'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MenuOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd';


function Banner({ heading, subheading }) {
  const items = [
    {
      label: <Link to="/">Home</Link>,
      key: '0',
    },
    {
      label: <Link to="/about">About Us</Link>
      ,
      key: '1',
    },
    {
      label: <Link to="/services">Services</Link>,
      key: '2',
    },
    {
      label: <Link to="/contact">Contact Us</Link>,
      key: '3',
    },
  ];

  return (
    <div className='main-container'>
      <div className="navbar">
        <div className="logo">
      <Link to="/">
          <img width={100} src={logo} alt="gym logo" />
        </Link>
        </div>
        <div>
          <Dropdown menu={{ items }} trigger={['click']}>
            <Link onClick={(e) => e.preventDefault()}>
              <Space>
                <MenuOutlined className='menu-icon' />
              </Space>
            </Link>
          </Dropdown>
        </div>

        <div className='nav-list'>
          <div>
            <Link to="/">Home</Link>
          </div>
          <div>
            <Link to="/about">About Us</Link>
          </div>
          <div>
            <Link to="/services">Services</Link>
          </div>
          <div>
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>

      </div>
      <div className='banner-heading' >   
           <p>{heading}</p>
      </div>
      <div className='banner-subheading'>
        <p>{subheading}</p>
      </div>
    </div>
  )
}

Banner.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
}

export default Banner
