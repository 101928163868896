import React from 'react'
import './home.css'


function TopWorkout() {
  return (
    <div className='workout-container'>
      <p className='workout'>Top Workout</p>

      <div className='cards'>
      <div className='card-design card1'>
        <p className='title'> Chest Workout</p>
        <p className='desc'>A chest workout targets and strengthens the muscles in the chest region. Exercises like bench presses, push-ups, and flyes are commonly used. Proper form and progression are important to prevent injuries. Consistent workouts can lead to improved muscle size, strength, and definition in the chest. </p>
      </div>
      <div className='card-design card2'>
      <p className='title'> Leg Workout</p>
        <p className='desc'>A leg workout targets the lower body muscles, including quads, hamstrings, glutes, and calves. It includes exercises like squats, lunges, deadlifts, and calf raises. Consistency and proper form can improve strength, stability, and muscle definition in the legs. </p>
      </div>
      <div className='card-design card3'>
      <p className='title'>Belly Workout</p>
        <p className='desc'>Leg workouts target lower body muscles (quads, hamstrings, glutes, calves) using exercises like squats, lunges, deadlifts, and calf raises. Proper form is vital to prevent injury and maximize results. Consistent training enhances strength, stability, and muscle definition in the legs. </p>
      </div>
      </div>

    </div>
  )
}

export default TopWorkout
