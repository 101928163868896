import React from 'react'
import Banner from '../../components/Banner'
import About from './About'
import Team from './Team'
import Footer from '../../components/Footer'

function AboutPage() {
  return (
    <div>
      <Banner heading="About Us" subheading='Start your fitness journey with us' />
      <About />
      <Team />
      <Footer number='9838765670, 9738478903' />
    </div>
  )
}

export default AboutPage
