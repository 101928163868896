import React from 'react'
import './services.css'

function Services() {
  return (
    <div className='service-container'>
      <p className='workout'>Our Services</p>

      <div className='cards'>
      <div className='card-design card4'>
        <p className='title'> Workout Programs</p>
        <p className='desc'>Workout programs are structured plans with specific exercises and progressions to help individuals achieve their fitness goals. They provide guidance, motivation, and a systematic approach to optimize workouts and track progress. Choose a program that aligns with your goals, adapt as needed, and stay consistent for optimal results. </p>
      </div>
      <div className='card-design card5'>
      <p className='title'>Personal Training</p>
        <p className='desc'>Personal training involves working one-on-one with a certified fitness professional who provides customized guidance, instruction, and support to help you reach your fitness goals. They assess your fitness level, create personalized workout plans, and offer expertise in exercises, form correction, and motivation for optimal results. </p>
      </div>
      <div className='card-design card6'>
      <p className='title'>Memberships</p>
        <p className='desc'>Gym memberships provide access to fitness facilities and amenities for a fee, allowing individuals to pursue their fitness goals and access a range of exercise options. They offer different durations and privileges to cater to personal preferences and budgets.</p>
      </div>
      </div>

    </div>
  )
}

export default Services
