import React from 'react'
import './about.css'
import gymbody from '../../images/gym-body.png'
function About() {
  return (
    <div className='about'>
      <div className='about-desc'>
        <p>Welcome to our gym! We are dedicated to promoting health, fitness, and overall well-being for our members. Our mission is to create a supportive and inclusive environment where individuals of all fitness levels can achieve their goals. Our state-of-the-art facilities offer a wide range of equipment, expert trainers, and diverse classes to ensure a comprehensive fitness experience. Whether you're looking to build strength, improve cardiovascular endurance, or enhance flexibility, we're here to support you every step of the way on your fitness journey. Join us and become a part of our fitness community committed to helping you reach your full potential.</p>
      </div>
      <div className='gym-body'>
        <img src={gymbody} alt="gymbody_image " />
      </div>
    </div>
  )
}

export default About
