import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import AboutPage from './pages/About Us/AboutPage';
import HomePage from './pages/Home/HomePage';
import ContactPage from './pages/Contact Us/ContactPage';
import ServicePage from './pages/Services/ServicePage';

function App() {

  return (

    <div className="App">

      <Router>
        <Routes>
          <Route exact path='/' element={<HomePage />} />
          <Route exact path='/about' element={<AboutPage />} />
          <Route exact path='/contact' element={<ContactPage />} />
          <Route exact path='/services' element={<ServicePage />} />
        </Routes>
      </Router>
    </div>

  );
}

export default App;
