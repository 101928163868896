import React from 'react'
import map from '../../images/map.png'
import location from '../../images/location.png'
import phone from '../../images/phone.png'
import mail from '../../images/mail.png'
import './contact.css'

function Contact() {
  return (
    <div className='contact'>
      <div className='contact-details'>
        <p>Contact Details</p>
        <div className="detail">
            <img width={15.18} height={17.6} src={location} alt="location" />
            <p>SRANCHIYA KUTTI MACHHA BAZAR NEAR BAJAJ AGENCY</p>
        </div>
         
          <div className='detail'>
            <img width={17.58} height={17.6} src={phone} alt="contact number" />
            <p>(+977) 9801608080 </p>
          </div>

      

        <div className='detail'>
          <img width={17.58} height={17.6} src={mail} alt="email" />
          <a className='mail' href="mailto: thedragonfitnesscenter@gmail.com">thedragonfitnesscenter@gmail.com</a>
        </div>
      </div>

      <div className='map'>
        <img src={map} alt="Map" />
      </div>
    </div>
  )
}

export default Contact