import { Button } from 'antd'
import './home.css'

import React from 'react'
import { Link } from 'react-router-dom'

function Membership() {
  return (
    <div className='membership'>
      <p>Membership Information</p>
      <p>To get a gym membership, research and visit local gyms to find one that suits your needs. Choose a membership plan and complete the registration process by providing personal information and making the required payment. Once registered, you will receive a membership card or key fob for gym access. Consider attending an orientation session if available to familiarize yourself with the equipment and workout techniques.</p>
      <Button size='228px' style={{height:'58px',marginTop:'70px',padding:'15px 60px',backgroundColor:'#FFDF00',fontFamily: 'Open Sans, sans-serif',
fontStyle: 'normal',
fontWeight: '700',
fontSize: '20px',
lineHeight: '27px',
textAlign: 'center',
border:'none',
borderRadius:'5px',
color:' #000000'}}>
  <Link to="/contact" style={{textDecoration:'none'}}>
  Contact Us
  </Link>
   </Button>
    </div>
  )
}

export default Membership
