import person1 from '../../images/person-1.png'
import person2 from '../../images/person-2.png'
import person3 from '../../images/person-3.png'
import './about.css'


function Team() {
    return (
        <div className='team-member'>
            <div>
                <p>Our Team</p>
            </div>
            <div className='team'>
                <div>
                    <img src={person2} alt=" team member " />
                    <p>Name Surname</p>
                    <p>Owner</p>
                </div>
                <div>
                    <img src={person1} alt=" team member" />
                    <p>Name Surname</p>
                    <p>Owner</p>
                </div>
                <div>
                    <img src={person3} alt="team member" />
                    <p>Name Surname</p>
                    <p>Trainer</p>
                </div>
                <div>
                    <img src={person3} alt="team member" />
                    <p>Name Surname</p>
                    <p>Trainer</p>
                </div>
            </div>
        </div>
    )
}

export default Team
